<template>
  <div>
    <img
      class="background-img"
      src="../../assets/images/wave.png"
      alt=""
    >
    <div class="login__container">
      <div class="login__container--content">
        <div class="left__content">

          <img
            class="aljay-logo"
            src="../../assets/images/new-logo-aljay.png"
            alt=""
          >
          <div class="inventory-image-container">
            <img
              class="inventory-image"
              src="../../assets/images/inventory-update.svg"
              alt=""
            >

          </div>
        </div>
        <div class="right__content">
          <div class="right__content--logo">
            <img
              class="aljay-logo-right"
              src="../../assets/images/new-logo-aljay.png"
              alt=""
            >
          </div>
          <div class="right__content--header">
            <i class="fas fa-user-circle login-icon" />
            <h1 class="text-header text-title">
              Dealers Inventory System
            </h1>
            <h2 class="text-header text-login">
              Welcome, User
            </h2>

            <ValidationObserver
              ref="form"
              v-slot="{ handleSubmit }"
            >
              <form

                novalidate
                @submit.prevent="handleSubmit(onSubmit)"
              >
                <ValidationProvider
                  #default="{ errors }"
                  vid="email"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group>
                    <label
                      class="form-text"
                      for="emailInput"
                    >Email</label>
                    <input
                      id="emailInput"
                      v-model="credentials.email"
                      type="email"
                      :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                      aria-describedby="emailHelp"
                      placeholder="Enter Email"
                      :disabled="isBusy"
                      autocomplete="off"
                    >
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider
                  #default="{ errors }"
                  vid="password"
                  name="password"
                  rules="required"
                >
                  <b-form-group>
                    <label
                      class="form-text"
                      for="passwordInput"
                    >Password</label>
                    <input
                      id="passwordInput"
                      v-model="credentials.password"
                      type="password"
                      :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                      placeholder="Enter Password"
                      required
                      :disabled="isBusy"
                      autocomplete="off"
                    >
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <div class="d-flex">
                  <button
                    type="submit"
                    block
                    :disabled="isBusy"
                    class="mb-1 btn-signin"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </ValidationObserver>

            <div class="login-with">
              <div />
              <p>or sign in with</p>
              <div />
            </div>

            <div class="row">
              <div class="col-lg-12">
                <button
                  type="submit"
                  block
                  :disabled="isBusy"
                  class="btn btn-secondary w-100"
                  style="border-radius: 50px"
                  @click="useAuthProvider('oauth2', Oauth2)"
                >
                  Aljay SSO
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SharedAuthService, SharedSSOService } from '@/services'
import { Oauth2 } from 'universal-social-auth'

export default {
  name: 'SharedSignIn',

  middleware: ['guest'],

  metaInfo () {
    return {
      title: 'Sign In'
    }
  },

  data: () => ({
    isBusy: false,
    credentials: {
      email: '',
      password: ''
    },
    Oauth2
  }),

  mounted () {
    core.index()
  },

  methods: {
    useAuthProvider (provider, proData) {
      this.$Oauth
        .authenticate(provider, proData)
        .then(data => {
          this.useSocialLogin(data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    async useSocialLogin (code) {
      await SharedSSOService.post(code)
        .then(response => {
          this.$store.dispatch('auth/saveToken', response.data.token)
          SharedAuthService.FetchUser()
            .then(({ data }) => {
              this.$store.dispatch('auth/fetchUserSuccess', data)
              let routeName = 'dashboard'
              const user = this.$store.getters['auth/user']
              if (user) {
                routeName = `${user.role}.dashboard`
              }
              this.$router.replace({ name: routeName })
            })
            .catch(() => {
              this.$store.dispatch('auth/fetchUserFailure')
            })
        })
        .catch(err => {
          console.log({ err: err })
        })
    },
    async onSubmit () {
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.isBusy = true
          SharedAuthService.SignIn(this.credentials).then(response => {
            this.$store.dispatch('auth/saveToken', response.data.token)

            SharedAuthService.FetchUser().then(({ data }) => {
              this.$store.dispatch('auth/fetchUserSuccess', data)

              let routeName = 'dashboard'

              const user = this.$store.getters['auth/user']

              if (user) {
                routeName = `${user.role}.dashboard`
              }

              this.$router.replace({ name: routeName })
            }).catch(() => {
              this.$store.dispatch('auth/fetchUserFailure')
            })

            this.isBusy = false
          }).catch(error => {
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
              }
            }
            this.isBusy = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.background-img {
    position:fixed;
    height: 100vh;
    width:50%;
    overflow: hidden;
    bottom: 0;
    left:0;
    z-index: -100;
}

.login__container {
  width: 100%;
  height: 100vh;
  overflow: visible;
  display: grid;

  &--content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    justify-content:center;
    overflow: hidden;
    height: 100%;
    .left__content {
      display:flex;
      flex-direction:column;
      padding: 2rem 5rem;
      height: 100%;
      justify-content:center;
      gap:4rem;
      align-items:center;
        .aljay-logo{
          object-fit:contain;
          height: 100px;
          margin-left: -4rem;
        }

        .inventory-image-container {
          display: flex;
          height: auto;
          align-items:center;
          width: 100%;
          .inventory-image {
            height: auto;
            width: 100%;
          }
        }

    }
    .right__content {
      padding:2rem 5rem;
      display:flex;
      flex-direction:column;
      justify-content: space-evenly;

      &--logo  {
        display:flex;
        display:none;
        .aljay-logo-right{
          object-fit:contain;
          height: 100px;
          margin: auto;
        }
      }
      &--header {
        .login-icon {
          width:100%;
          font-size:36px;
          color:#006353;
          text-align: center;
        }
        .text-title, .text-login {
          text-align:center;
          color: #006353;
        }

        .text-title {
           font-weight:600;
          font-size: 30px;
        }
        .text-login {
          font-size: 24px;
           font-weight:400;
        }
      }
      .btn-signin {
        width:100%;
        border-radius: 60px;
        margin-top:10px;
        border:none;
        background: #006353;
        color: #eee;
        padding: 5px;
        font-family: 'Muli', sans-serif;
        &:hover {
          opacity: 0.9;
          background: #006353;
          color:white;

        }
      }

      .form-text {
        color:black;
      }
    }

    .login-with {
      display: grid;
      grid-template-columns: 1fr max-content 1fr;
      align-items:center;
      justify-content:center;
        div {
          height: 0.8px;
          background: #ced4da;
        }
        p {
          margin: 0;
          padding: 0.5rem 1rem;
        }

    }

  }
}

@media screen and (max-width: 567px) {
  .background-img {
    width:100%;
  }
  .login__container {
      &--content {
        .left__content {
          display:none;
        }

        .right__content {
          grid-column: 1 / span 2;
          padding: 0 2.5rem;
          justify-content:center;
          gap: 2.5rem;
          &--logo {
            margin: 0 auto;
            display:block;
          }
          .text-title {
            font-size: 24px;
          }
          .text-login {
            font-size: 18px;
          }
          .form-text {
            color:#eee;
          }
          .login-with {
             div {
              background: white;
            }
            p {
              color: #eee;
            }
          }
        }
      }
  }
}
@media screen and (min-width: 568px) and (max-width: 767px) {
  .background-img {
    width:100%;
  }
  .login__container {
      &--content {
        .left__content {
          display:none;

        }

        .right__content {
          grid-column: 1 / span 2;
          padding: 0 2.5rem;
          justify-content:center;
          gap: 2.5rem;
          &--logo {
            margin: 0 auto;
            display:block;
          }
          .text-title {
            font-size: 24px;
          }
          .text-login {
            font-size: 18px;
          }
          .form-text {
            color:#eee;
          }
          .login-with {
            div {
              background: white;
            }
            p {
              color: #eee;
            }
          }
        }
      }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .background-img {
    width:50%;
  }
  .login__container {
      &--content {
        .left__content {
          padding: 2rem;
          width: 100%;
          justify-content:center;
           .aljay-logo{
             height:60px;
              margin-left: 0;
            }
        }
        .right__content {
          padding: 2rem;

        }

    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .background-img {
    width:50%;
  }

  .login__container {
    &--content {
      .left__content {
          padding: 2rem;
          width: 100%;
          gap: 4rem;
          justify-content:center;
          .aljay-logo {
            margin-left: 0;
          }
      }
      .right__content {
        padding: 2rem;
      }
    }
  }

}
</style>
